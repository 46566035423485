import {BrowserRouter as Router, Switch,Route} from 'react-router-dom';
import Homeone from './pages/Homeone';
import Movie from './pages/Movie';
import MovieDetails from './pages/MovieDetails';
import TvSeries from './pages/TvSeries';
import { useEffect } from "react"
import $ from "jquery";
import Pricing from './pages/Pricing';
import Blogs from './pages/Blogs';
import BlogDetails from './pages/BlogDetails';
import ContactPage from './pages/ContactPage';
import DocumentalDetail from './pages/DocumentalDetail';
import ProjectPage from './pages/ProjectPage';
import StartPage from './pages/StartPage';
import Login from './pages/Login';
import SearchPage from './pages/SearchPage';
import { CookiesProvider } from 'react-cookie';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import NotFound from './pages/NotFound';
import ReactGA from 'react-ga';

const TRACKING_GA_ID = 'G-NPP8J151LY'
ReactGA.initialize(TRACKING_GA_ID)

function App() {

  
  useEffect(() => {
    function preloader() {
      $('#preloader').delay(0).fadeOut();
    };
    $(window).on('load', function () {
      preloader();
    });
  })

  return (
    <CookiesProvider>
    <div className="App">
     <Router>
       
        <Switch>
          <Route exact={true} path="/">
            <StartPage/>
          </Route>
          <Route exact={true} path="/login">
            <Login/>
          </Route>
          <Route exact={true} path="/home">
            <Homeone/>
          </Route>
          <Route exact={true} path="/murmullos">
            <DocumentalDetail />
          </Route>
          <Route exact={true} path="/interviews">
            <Movie/>
          </Route>
          <Route exact={true} path="/movie-details/:id">
            <MovieDetails/>

          </Route>
          <Route exact={true} path="/aviso-privacidad">
            <AvisoPrivacidad />
          </Route>

          <Route exact={true} path="/project">
            <ProjectPage />
          </Route>
          <Route exact={true} path="/search">
            <SearchPage />
          </Route>
          <Route exact={true} path="/tv-show">
            <TvSeries/>

          </Route>
          <Route exact={true} path="/pricing">
            <Pricing/>

          </Route>
          <Route exact={true} path="/blog">
            <Blogs/>

          </Route>
          <Route exact={true} path="/blog-details">
            <BlogDetails/>

          </Route>
         
          <Route exact={true} path="/contact">
            <ContactPage/>

          </Route>
          <Route component={NotFound} />
        </Switch>
        
     </Router>
     
      
        
    </div>
    </CookiesProvider>
  );
}

export default App;
