import React,{ useEffect, useState } from 'react'
import "magnific-popup";

import 'animate.css';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import {LocalStorageService} from '../services/local-storage.service';
import { AuthService } from '../services/authService';
import { STORAGE } from '../constants';


const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState('')
  const history = useHistory()

  useEffect(() => {
    const sess_token = LocalStorageService.get(STORAGE.TOKEN)
    if (sess_token) {
      history.push('/home')
    }
  }, [])
  

  const onChangeHandler = (e) => {
    if (e.target.name === 'username') {
      setUsername(e.target.value)
    } else {
      setPassword(e.target.value)
    }
  }

  const doLogin = async() => {
    try {
      const response = await AuthService.login({ username, password })
      LocalStorageService.save(STORAGE.TOKEN, response.token);
      LocalStorageService.save(STORAGE.SESSION, response.user);
      history.push('/home');
    } catch (error) {
      console.log(error)
      setErrors(error.message)
    }
    // if (username === 'pruebas' && password === 'murmullo2022') {
    //   const user = {
    //     username: 'pruebas',
    //     token: 'c32d8b45-92fe-44f6-8b61-42c2107dfe87'
    //   };

    //   // LocalStorageService.signIn(user)
    //   let expires = new Date();
    //   let minutesSession = 480;
    //   expires.setTime(expires.getTime() + (minutesSession*60*1000));
    //   setCookie('access_token', user.token, { path: '/',  expires})
    //   setErrors('')
    //   history.push('/home')
    // } else {
    //   setErrors('Usuario y/o contraseña incorrectos')
    // }
  }


  return (
    <section className="banner-area banner-login banner-bg" style={{backgroundImage:'url("../img/site/header-interna.jpeg")'}}>
    <div className="container custom-container">
      <div className="row align-items-center justify-content-center">
        <div className="col-xl-5 col-lg-6">
       
         <div className="banner-content ">
            <div className="animate__animated animate__fadeInUp mb-4" data-wow-delay=".2s" data-wow-duration="1.8s">
              <img src="img/site/logotipo-negro_rojo-01.svg" alt="" />
            </div>
            <h2 className="title animate__animated animate__fadeInUp" data-wow-delay=".4s" data-wow-duration="1.8s">Escuchando a los hijos de sobrevivientes del Holocausto en México.</h2>
            <div className="login-form text-center">
              <form>
                <div className="errors text-danger mb-3">{errors}</div>
                <div className="form-group">
                  <input 
                    type="text" 
                    name="username" 
                    placeholder="Usuario" 
                    className="form-control" 
                    value={username}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="form-group">
                  <input 
                    type="password" 
                    name="password" 
                    placeholder="Contraseña" 
                    className="form-control" 
                    value={password}
                    onChange={onChangeHandler}
                  />
                </div>
                <button type="button" className="banner-btn btn" onClick={doLogin}><i className="fas fa-user" /> Ingresar</button>
              </form>
            </div>
            
          </div>
         
        </div>
      </div>
    </div>
  </section>
  )
}

export default Login