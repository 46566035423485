import React, { useState, useEffect } from 'react'
import CarouselArea from '../components/carousel-area/CarouselArea'
import NewsCard from '../components/documental/NewsCard'
import TestimonialSection from '../components/documental/TestimonialSection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewsletterArea from '../components/homeone/NewsletterArea'
import { Breadcrumb } from '../components/movie/Breadcrumb'
import { NotesService } from '../services/notesService'

const ProjectPage = () => {
  const [notes, setNotes] = useState([])
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    loadNotes()
  }, [])

  const loadNotes = async () => {
    try {
      const response = await NotesService.getAll();
      setNotes(response)
    } catch (error) {
      console.log(error);
    }
  }

  const buildCards = (item) => {
    return <NewsCard note={item} />
  }

  return (
    <div>
     <Header/>
    <main>
        <Breadcrumb title="Proyecto" />
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title title-style-three text-center mb-60">
                  <h2 className="title">Equipo de producción</h2>
                </div>
              </div>
            </div>
            <div className="item-team mb-20">
              <div className="row">
                <div className="col-md-4">
                  <img src="img/site/aaron.jpeg" alt=""/>
                </div>
                <div className="col-md-8">
                  <div className="section-title mb-30">
                    <h2 className="title mb-3">Aaron Cohen Hop</h2>
                    <span className="sub-title">DIRECTOR DEL DOCUMENTAL “ MURMULLOS DEL SILENCIO”</span>
                  </div>
                  <p>Licenciado en Periodismo y Comunicación por la Universidad Nacional Autonoma de Mexico. (1973-1977)</p>
                  <p>Fundador y director de las empresas Imagen Audiovisual (1978-19929, Weisz y Cohen Producciones (1992- 2003), Cohen y Cohen Creatividad y Momentum Films. (2003-)</p>
                  <p>Por más de 40 años se ha dedicado a la producción de fotografía, espectáculos multimedia, videos y documentales. </p>
                  <p>Entre los eventos en que ha participado como productor de video se encuentran el Festival Aviv y La Marcha de la Vida. Ha producido eventos para varias instituciones de la Comunidad Judía de México y de México.</p>
                  <p>Ha dirigido documentales con temática del holocausto como Ana Frank, Justos entre las Naciones, Abogados sin Derechos entre otros para el Museo Histórico Judío y del Holocausto Tuvie Maizel.</p>
                  <p>Es el director del documental “Paraíso en Auschwitz” y “Murmullos del Silencio “.</p>
                </div>
              </div>
            </div>
            <div className="item-team mb-20">
              <div className="row">
                <div className="col-md-4">
                  <img src="img/site/esther.jpeg" alt=""/>
                </div>
                <div className="col-md-8">
                  <div className="section-title mb-30">
                    <h2 className="title mb-3">Esther Bejar de Cohen</h2>
                    <span className="sub-title">PRODUCTORA DEL DOCUMENTAL "MURMULLOS DEL SILENCIO"</span>
                  </div>
                  <p>Licenciada en Comunicación por la Universidad Iberoamericana con especialidad en Investigación de la Comunicación. (1973-1977)</p>
                  <p>Trabajo en la cadena de televisión Televisa en el departamento de prensa y fotografía. (1974-1978)</p>
                  <p>Durante mas de 40 años ha trabajado a la par de su esposo Aaron Cohen Hop en las empresas que ambos fundaron.</p>
                  <p>Ha producido material audiovisual, videos, espectáculos multimedia para empresas e instituciones de la Comunidad Judía de México y de México.</p>
                  <p>De 1996 a 2000 produjo el programa de televisión “a través del Espejo” con la escritora Guadalupe Loaeza para canal 40.</p>
                  <p>Produjo el material audiovisual para la exposición permanente en el museo Histórico Judío y del holocausto “Tuvie Maizel”.</p>
                  <p>Ha realizado entrevistas con sobrevivientes e hijos de sobrevivientes del Holocausto.</p>
                  <p>Es productora del documental “Paraíso en Auschwitz” y “Murmullos del Silencio”</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title title-style-three text-center mb-60">
                  <h2 className="title">Murmullos del silencio, el proyecto</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
              <p>EL SILENCIO NO ES OLVIDO.</p>
              <p>TRAUMA…MEMORIA…SILENCIO…LAZOS FAMILIARES Y TRANSMISIÓN.</p>
              <p>¿DE QUÉ MANERA EL HORROR VIVIDO POR LOS SOBREVIVIENTES DEL HOLOCAUSTO AFECTÓ LA MANERA DE PERCIBIR EL MUNDO DE SUS HIJOS?</p>
              <p className="text-justify">Después de cuatro años de un intenso trabajo, presentamos “Murmullos del Silencio” dedicado a la memoria de nuestros queridos hermanos Jenny y Salomón quienes fallecieron en el año 2020, año de la pandemia, después de una intensa lucha contra el cáncer.  </p>
              <p className="text-justify">Este trabajo es producto de una ardua investigación sobre la transmisión de la violencia intergeneracional y representa una semblanza de la segunda generación y de cómo las guerras, matanzas colectivas, genocidios y cualquier tipo de violencia siguen teniendo vida propia muchos años después de haber sucedido. En estos hijos de la postguerra y el Holocausto siguen latiendo los ecos del mayor crimen contra la humanidad y la esperanza de una nueva vida en paz, tolerancia y armonía.</p>
              <p className="text-justify">Para nosotros este trabajo simboliza la revelación de un mundo desconocido que nos ha dejado un gran aprendizaje y ha significado un crecimiento personal muy importante. Comienza con un recuento histórico de lo que representó para los sobrevivientes del holocausto la liberación, el darse cuenta de el despojo absoluto de su humanidad, la pérdida de su país, su hogar, su familia y el duro exilio que tuvieron que vivir y que significó reinventarse e iniciar una nueva vida en México.</p>
              
              </div>
              <div className="col-lg-6">
                <p className="text-justify">Cada testimonio representa una experiencia única y singular, en cada historia hay detrás una prueba de amor incondicional, profundo e infinito. El silencio no es olvido, para los padres es protección y respeto al mismo tiempo, está presente como un arma contra el sufrimiento y se torna en la posibilidad de construir una nueva vida donde los horrores del pasado queden sepultados y les permita renacer y seguir adelante. Para los hijos es resguardo, amparo, la necesidad de cuidar a sus seres más queridos con el fin de evitar el dolor y el sufrimiento vivido y percibido en cada gesto, sin palabras.</p>
                <p className="text-justify">Sin embargo, este silencio les permeo a tal grado que significó una barrera infranqueable contra la verdad, el horror y la vergüenza de lo vivido por los padres y fue transmitido a los hijos sin palabras. Era tan grande el dolor que no querían causar más dolor en sus familias y los hijos por respeto no se atrevían a preguntar, intuían que era algo intocable. </p>
                <p className="text-justify">Así mismo queremos agradecer de todo corazón a los hijos de sobrevivientes que nos permitieron entrevistarlos y nos abrieron las puertas de su casa, su alma y su corazón para contarnos sus historias tan íntimas y personales. Muchos de ellos son amigos de hace muchos años que hablaron por primera vez, que accedieron a revelarnos sus sentimientos más profundos sobre el tema y que nos conmovieron hasta las lágrimas. </p>
                <p className="text-justify">Deseamos que este trabajo que hemos realizado con el corazón trascienda fronteras, sea esclarecedor y lleve un mensaje de paz, tolerancia, conciencia y armonía para el mundo y las futuras generaciones.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title title-style-three text-center mb-60">
                  <h2 className="title">Prensa</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center carousel-section">
              <div className="col-12">
                <CarouselArea 
                  items={notes}
                  renderItems={buildCards}
                  settings={{slidesToShow: 3}}
                />
              </div>
            </div>
          </div>
        </section>
        <TestimonialSection />
        <NewsletterArea/>
    </main>
     <Footer/>
    </div>
  )
}

export default ProjectPage