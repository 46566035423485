import React, { useState, useEffect } from 'react'
import GallerySection from '../components/documental/GallerySection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewsletterArea from '../components/homeone/NewsletterArea'
import { Breadcrumb } from '../components/movie/Breadcrumb'
import MovieArea from '../components/movie/MovieArea'
// import ReactPlayer from 'react-player'
import NewsletterAreaDocumental from '../components/homeone/NewsletterAreaDocumental'
// import VideoPlayer from '../components/video-player/VideoPlayer';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import { GalleryService } from '../services/galleryService'
import ReactGA from 'react-ga';

const DocumentalDetail = () => {
  const [gallery, setGallery] = useState([])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // ReactGA.pageview(window.location.pathname + window.location.search);
    loadGallery()
  }, [])

  const loadGallery = async () => {
    try {
      const response = await GalleryService.getAll()
      setGallery(response)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
     <Header/>
    <main>
        <Breadcrumb title="Murmullos del silencio" />
        <section className="live-area live-bg fix">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="services-content-wrap light-section">
                  <div className="section-title title-style-four mb-20">
                    <span className="sub-title">Murmullos del silencio</span>
                    <h2 className="title">Sinopsis</h2>
                  </div>
                  <p className="mb-15">Mayo de 1945. Termina la Segunda Guerra Mundial y se liberan los campos de concentración. Cientos de miles de sobrevivientes del Holocausto son liberados. Al parecer es un momento feliz pero para ellos son momentos  de confrontación y trauma con su nueva realidad: de su deteriorada situación física, de la pérdida de sus familias, de sus hogares, e incluso de de sus países a los que ya no pueden regresar. Primero se tienen que recuperar física y emocionalmente para después iniciar una larga y dolorosa odisea de muchos años para tratar de emigrar a otros países y reconstruir sus vidas.</p>
                  <p className="mb-15">Algunos de ellos lograron contactar con familiares en México, los que les ayudaron a tramitar sus visas y así pudieron llegar a este nuevo y desconocido país. </p>
                  <p className="mb-15">Así, poco a poco,  comienzan su difícil proceso de adaptación a una nueva cultura, un nuevo idioma, a un nuevo clima.</p>
                  <p className="mb-15">Empiezan a trabajar, a casarse, a formar familias y tener hijos. Pero el trauma del Holocausto siempre estará presente en sus vidas y se lo transmiten a sus hijos a través de sus secretos, miedos, sus traumas, sus culpas, sus pesadillas, a través de los “Murmullos del Silencio”. </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="live-movie-img animate__animated animate__fadeInRight" data-wow-delay=".2s" data-wow-duration="1.8s">
                  <img src="img/site/img-murmullos-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section dark pb-2 pt-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {/* <ReactPlayer width={'100%'} height={480} controls url='https://murmullos-media.s3.us-west-2.amazonaws.com/videos/MURMULLOS_SPA_51.mp4' /> */}
                {/* <VideoPlayer
                  url='https://murmullos-media.s3.us-west-2.amazonaws.com/videos/MURMULLOS_SPA_51.mp4'
                  poster='/img/site/portada_video.jpeg'
                /> */}
                <div className="video-container">
                  <Player 
                    src={'https://murmullos-media.s3.us-west-2.amazonaws.com/videos/MURMULLOS_SPA_51.mp4'}
                    poster='/img/site/portada-documental.png'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <GallerySection gallery={gallery} />
        <NewsletterAreaDocumental />
    </main>
     <Footer/>
    </div>
  )
}

export default DocumentalDetail