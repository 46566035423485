import React from 'react'

export const BreadcrumbSearch = (props) => {
  return (
    <section className="breadcrumb-area breadcrumb-bg" style={{backgroundImage:'url("../img/site/header.jpeg")', backgroundPosition: 'top', paddingTop: '100px', paddingBottom: '100px'}}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="breadcrumb-content">
            <h2 className="title"><span>Resultados de la búsqueda</span></h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" style={{ fontSize: '20px', fontWeight: '400' }} aria-current="page">{`" ${props.title || ''} "`}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
