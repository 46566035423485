import React, { useEffect, useState } from 'react';
// import { interviews } from '../../data/interviews.const';
import CarouselArea from '../carousel-area/CarouselArea';
import CardPerson from '../interviewed/CardPerson';

const InterviewedSection = ({ interviews }) => {
  const [filterInterviews, setFilterInterviews] = useState([])

  useEffect(() => {
    setFilterInterviews(interviews);
  }, [interviews])
  
  // const getRandomItems = (arr, num) => {
  //   const shuffled = [...arr].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, num);
  // }

  const buildCardPerson = (item) => {
    return <CardPerson person={item} />
  }

  return (
    <section className="interviewed-section section dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <span className="sub-title">ENTREVISTAS</span>
              <h2 className="title">Hijos de los sobrevivientes</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center carousel-section">
          <div className="col-12">
            <CarouselArea 
              items={filterInterviews}
              renderItems={buildCardPerson}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default InterviewedSection;