import React, { useEffect, useState } from 'react';
import Banner from '../components/homeone/Banner';
import NewsletterArea from '../components/homeone/NewsletterArea';
import Footer from '../components/Footer';
import Header from "../components/Header";
import AboutSection from '../components/homeone/AboutSection';
import InterviewedSection from '../components/homeone/InterviewedSection';
import MoviesSliderSection from '../components/homeone/MoviesSliderSection';
import Sound from 'react-sound';
import musicBg from '../assets/media/intro-music.mp3';
import { InterviewService } from '../services/interviewService';
import ReactGA from 'react-ga';


const Homeone = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hijosSobrevivientes, setHijosSobrevivientes] = useState([])
  const [topInterviews, setTopInterviews] = useState([])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.getElementById('btn-sound').click()
    // ReactGA.pageview(window.location.pathname + window.location.search);
    loadInterviewsSobrevivientes()
    loadTopInterviews()
  }, [])

  const loadInterviewsSobrevivientes = async () => {
    try {
      const response = await InterviewService.getRandom(10, 'hijos-de-sobrevivientes')
      setHijosSobrevivientes(response)
    } catch (error) {
      console.log(error)
    }
  }

  const loadTopInterviews = async () => {
    try {
      const response = await InterviewService.getPopular(10)
      setTopInterviews(response)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <Header />
      <main>
        <Banner />
        <AboutSection />
        <InterviewedSection interviews={hijosSobrevivientes} />
        <MoviesSliderSection interviews={topInterviews} />
        <NewsletterArea />
      </main>
      <Footer />
      <div style={{
        position: 'fixed',
        bottom: 85,
        right: 16
      }}>
        <button
          id="btn-sound"
          className='btn btn-sound'
          onClick={() => setIsPlaying(!isPlaying)}
        >
          {!isPlaying ? <i className="fas fa-play"></i> : <i className="fas fa-pause"></i>}
        </button>
        <Sound
          url={musicBg}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
          volume={30}
          autoLoad={true}
          loop={true}
        />
      </div>
    </div>

  )
}

export default Homeone
