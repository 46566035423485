import React,{ useEffect } from 'react';
import $ from "jquery";
import 'magnific-popup';
import Counterup from '../Counterup';
import 'animate.css';



const AboutSection = () => {
  useEffect(()=>{
    $('.popup-video').magnificPopup({
			type: 'iframe'
		});
  },[])

  return (
    <section className="live-area live-bg fix">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-7 col-lg-6">
          <div className="live-movie-img animate__animated animate__fadeInRight d-flex justify-content-center" data-wow-delay=".2s" data-wow-duration="1.8s" >
            <div className="" style={{ position: 'relative' }}>
              <img src="img/site/img-murmullos.png" alt="" />
              <a 
                href="https://www.youtube.com/watch?v=rd3A3fKyCEU"
                className="popup-video"
                data-delay=".8s"
                style={{
                  border: 0,
                  backgroundColor: '#c70d25',
                  borderRadius: '50%',
                  color: '#fff',
                  width: '130px',
                  height: '130px',
                  fontSize: '32px',
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 40,
                  right: 40,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '2px 2px 10px 3px rgba(0,0,0,.3)'
                }}
              >
                <i className="fa fa-play mb-1" />
                <span style={{ fontSize: '13px' }}>Ver trailer</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-lg-6">
          <div className="services-content-wrap light-section">
            <div className="section-title title-style-two mb-20">
              <span className="sub-title">Murmullos del silencio</span>
              {/* <h2 className="title">Plataforma streaming que recopila más de 120 entrevistas.</h2> */}
            </div>
            <p className="h3 text-dark font-weight-normal"><strong>Plataforma streaming</strong> que recopila testimonios de <strong>hijos de sobrevivientes y sobrevivientes del Holocausto que migraron a México,</strong> así como de <strong>especialistas</strong> (psicoterapeutas, sociólogos, historiadores, filósofos, antropólogos sociales, escritores, etc) <strong>que reflexionan sobre el contenido del documental "Murmullos del Silencio".</strong></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default AboutSection;