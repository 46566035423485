import React, { useEffect, useState } from 'react'
import CardPerson from '../interviewed/CardPerson';

function SearchResultsArea({searchResults}) {

  return (
    <section className="movie-area movie-bg">
      <div className="container">
        <div className="row tr-movie-active">
          { searchResults.length < 1 && <div className="text-center"><h4 className="text-center">Sin resultados</h4></div> }
          {
            searchResults.map((elem, index) => {
              return (
                <div className="col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two" key={index}>
                  <CardPerson person={elem} />
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default SearchResultsArea