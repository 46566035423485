import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
        <div className="footer-top-wrap">
          <div className="container">
            <div className="footer-menu-wrap">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="footer-logo">
                    <Link to="/"><img src="/img/site/logotipo-bco.png" alt="" /></Link>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="footer-menu">
                    <nav>
                      <ul className="navigation">
                        <li><Link to="/home">Inicio</Link></li>
                        <li><Link to="/murmullos">Murmullos del silencio</Link></li>
                        <li><Link to="/interviews">Entrevistas</Link></li>
                        <li><Link to="/project">Proyecto</Link></li>
                        <li><Link to="/contact">Contacto</Link></li>
                      </ul>
                      {/* <div className="footer-search">
                        <form action="/#">
                          <input type="text" placeholder="Find Favorite Movie" />
                          <button><i className="fas fa-search" /></button>
                        </form>
                      </div> */}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-quick-link-wrap">
              <div className="row align-items-center">
                <div className="col-md-7">
                  {/* <div className="quick-link-list">
                    <ul>
                      <li><a href="/#">FAQ</a></li>
                      <li><a href="/#">Help Center</a></li>
                      <li><a href="/#">Terms of Use</a></li>
                      <li><a href="/#">Privacy</a></li>
                    </ul>
                  </div> */}
                </div>
                <div className="col-md-5">
                  <div className="footer-social">
                    <ul>
                      <li><a href="https://www.facebook.com/MomentumFilmsMX/" target='_blank'><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="https://www.instagram.com/momentum.filmsmx/?igshid=MzNlNGNkZWQ4Mg==" target='_blank'><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text text-center">
                  <p>2022 © Copyright | <Link to="/aviso-privacidad" alt="Aviso de privacidad">Aviso de privacidad</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer