import React, { useState } from 'react'
import { ContactService } from '../../services/contactService';

const initContactValues = {
  name: '',
  email: '',
  subject: '',
  message: ''
};

const Contacts = () => {
  const [formContact, setFormContact] = useState(initContactValues)

  const changeInputHandler = (e) => {
    setFormContact({
      ...formContact,
      [e.target.name]: e.target.value
    })
  }

  const resetForm = () => {
    setFormContact(initContactValues)
    alert('Su mensaje ha sido enviado, en breve nos pondremos en contacto.')
  }

  const sendForm = async () => {

    if (!formContact.name || !formContact.email || !formContact.subject || !formContact.message) {
      alert('Debe llenar todos los campos del formulario')
      return false;
    } else if (!isValidEmail(formContact.email)) {
      alert('Ingrese un correo valido')
      return false;
    } else {
      try {
        await ContactService.sendForm(formContact)
        resetForm()
      } catch (error) {
        console.log('formContactError::', error);
      }
      
    }
  }

  const isValidEmail = (emailVal) => {
    const validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    // Using test we can check if the text match the pattern
    if (validEmail.test(emailVal)) {
      return true;
    } else {
      return false;
    }
  }

  return (

    <section className="contact-area contact-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-7">
            <div className="contact-form-wrap">
              <div className="widget-title mb-50">
                <h5 className="title">Contactanos</h5>
              </div>
              <div className="contact-form dark">
                <form action="#">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        placeholder="Nombre"
                        value={formContact.name}
                        onChange={changeInputHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        placeholder="Email"
                        name='email'
                        value={formContact.email}
                        onChange={changeInputHandler}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Asunto"
                    name='subject'
                    value={formContact.subject}
                    onChange={changeInputHandler}
                  />
                  <textarea
                    name="message"
                    placeholder="Mensaje..."
                    value={formContact.message}
                    onChange={changeInputHandler}
                  />
                  <button type="button" className="btn" onClick={sendForm}>Enviar</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="widget-title mb-85">
            </div>
            <div className="contact-info-wrap">
              <p className="text-light">Escribenos tus comentarios o solicita mayor información del documental y entrevistas. Gracias.</p>
              <div className="contact-info-list">
                <ul>
                  <li>
                    <div className="icon"><i className="fab fa-whatsapp"></i></div>
                    <p><span>Whatsapp: </span> <a href="https://wa.me/525521066593?text=Hola!" target="_blank">+52 55 2106 6593</a></p>
                  </li>
                  <li>
                    <div className="icon"><i className="fas fa-envelope" /></div>
                    <p><span>Email:</span> <a href="mailto:murmullosinfo@gmail.com">murmullosinfo@gmail.com</a></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  )
}

export default Contacts