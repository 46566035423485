import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import BannerLogin from '../components/login/BannerLogin';


const StartPage = () => {
  useEffect(() => {
    console.log('pageview');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
  <div>
    <main>
        <BannerLogin />
    </main>
  </div>

  )
}

export default StartPage
