import React from 'react';

const NewsCard = ({ note }) => {
  return (
    <div className="card-person">
      <div className="person-photo">
        <a href={note.url} target="_blank">
          <img src={note.photo} alt="" />
        </a>
      </div>
      <div className="person-content text-center">
          <h5 className="title">
            <a href={note.url} target="_blank">{ note.title }</a>
          </h5>
      </div>
    </div>
  )
};

export default NewsCard;