import axios from 'axios';
import { STORAGE } from '../constants';
import {LocalStorageService} from './local-storage.service';


const _HTTP_ = axios.create();

_HTTP_.interceptors.request.use(
    (config) => {
        const token = LocalStorageService.get(STORAGE.TOKEN);
        if(token) {
            config.headers['token-api'] = `${token}`;
        }

        return config;
    }
)

_HTTP_.interceptors.response.use(
    (response) => response.data,
    (error) => {
        console.log('HTTP::interceptor::error', error)
        const { status } = error.response;
        if (status === 401) {
            LocalStorageService.clear();
            window.location.replace('/login');
        }

        return Promise.reject(error.response.data || { message: 'Unknown error' });
    }
)

export const HTTP = _HTTP_;
