let _END_POINT_ = 'http://localhost:8080/';
let env = process.env.REACT_APP_STAGE;

switch (env) {
  case 'prod':
    _END_POINT_ = 'https://api.murmullosdelsilencio.com/'
    break;

  default:
    break;
}

export const END_POINT = _END_POINT_;
export const STORAGE = {
  TOKEN: 'ms-token',
  SESSION: 'ms-session'
};