import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import { interviews } from '../../data/interviews.const';
import CardPerson from '../interviewed/CardPerson';
import Menu from './MovieMenu';
import { CategoryService } from '../../services/categoryService';
import { InterviewService } from '../../services/interviewService';

function MovieArea() {
  const [items, setItems ] = useState([]);
  const [interviews, setInterviews] = useState([])
  const [filterItems, setFilterItems] = useState([])
  const [categories, setCategories] = useState([])
  const [categorySelect, setCategorySelect] = useState(null);
  // const categories = ['Hijos de sobrevivientes', 'Especialistas', 'Sobrevivientes', 'Todas']

  useEffect(() => {
    let sortedInterviews = sortInterviews();
    setItems(sortedInterviews);
    loadCategories()
    loadInterviews()
  }, [])

  const loadCategories = async () => {
    try {
      const response = await CategoryService.getAll()
      let cat = sortElements(response, 'title')
      setCategories(cat)
    } catch (error) {
      console.log(error);
    }
  }

  const loadInterviews = async () => {
    try {
      const response = await InterviewService.getAll()
      let items = sortElements(response, 'name')
      setInterviews(items)
      setFilterItems(items)
    } catch (error) {
      console.log(error);
    }
  }
  

  const changeCategory = (category) => {
    // console.log('Filter category:', category)
    setCategorySelect(category);
    if (category === null) {
      // let sortedInterviews = sortElements(interviews)
      setFilterItems(interviews)
    } else {
      filterItem(category)
    }
  }

  const sortElements = (elements, key) => {
    return elements.sort((item1, item2) => {
      let name1 = item1[key].toUpperCase();
      let name2 = item2[key].toUpperCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }

      return 0;
    })
  }

  const sortInterviews = () => {
    return interviews.sort((item1, item2) => {
      let name1 = item1.nombre.toUpperCase();
      let name2 = item2.nombre.toUpperCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }

      return 0;
    })
  }

  const filterItem = (categItem)=>{
    // let sortedInterviews = sortInterviews();
    const updatesItems = interviews.filter((curElem)=>{
      return curElem.category && curElem.category.title.toLowerCase() === categItem.title.toLowerCase();

    })

    setFilterItems(updatesItems)
  }

  return (
    <section className="movie-area movie-bg">
    <div className="container">
      {/* <div className="row align-items-end mb-60">
        <div className="col-lg-6">
          <div className="section-title text-center text-lg-left">
            <span className="sub-title">Loremp Ipsum</span>
            <h2 className="title">Entrevistas</h2>
          </div>
        </div>
        
      </div> */}
      <div className="row mb-5">
        <div className="col-12 text-center">
          { 
            categories.map((cat, index) => {
              return (
                <button 
                  onClick={() => changeCategory(cat)} 
                  className={`btn mx-1 ${categorySelect && cat._id === categorySelect._id ? 'btn-cat-active' : ''}`} 
                  style={{maxWidth: '250px'}} 
                  key={`cat-${index}`}
                >
                  {cat.title}
                </button>
              )
            })
          }
          <button 
            onClick={() => changeCategory(null)} 
            className={`btn mx-1 ${categorySelect ? '' : 'btn-cat-active'}`} 
            style={{maxWidth: '250px'}} 
            key={`cat-0`}
          >
            Todas
          </button>
        </div>
      </div>
      <div className="row tr-movie-active">
      {
        filterItems.map((elem, index)=>{
                {/* const {id,image,title,date,quality,duration,ratings} = elem; */}

          return(
             <div className="col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two" key={index}>
              <CardPerson person={elem} />
            </div>
          )
        })

      }
    
      </div>
      {/* <div className="row">
        <div className="col-12">
          <div className="pagination-wrap mt-30">
            <nav>
              <ul>
                <li className="active"><a href="/#">1</a></li>
                <li><a href="/#">2</a></li>
                <li><a href="/#">3</a></li>
                <li><a href="/#">4</a></li>
                <li><a href="/#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div> */}
    </div>
  </section>
  )
}

export default MovieArea