import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const AuthService = {

    login: (data) => {
        return HTTP.post(`${END_POINT}auth/login`, data);
    },
    forgotPassword: (data) => {
        return HTTP.post(`${END_POINT}auth/forgot-password`, data);
    },
    validateToken: (data) => {
        return HTTP.post(`${END_POINT}auth/forgot-password/validate`, data);
    }
}