import React, { useCallback, useState, useEffect } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../../data/photos";

const GallerySection = ({ gallery }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [images, setImages] = useState([])

  useEffect(() => {
    if (Array.isArray(gallery)) {
      let pictures = []
      for (let i = 0; i < gallery.length; i++) {
        const element = gallery[i];
        
        pictures.push({
          src: element.photo,
          title: element.title,
          width: 1,
          height: 1
        })
      }

      setImages(pictures)
    } else {
      setImages([])
    }
  }, [gallery])
  

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <section className="interviewed-section section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <span className="sub-title">Documental</span>
              <h2 className="title">Galería</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center carousel-section">
          <div className="col-12">
            <Gallery photos={images} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel 
                    currentIndex={currentImage}
                    views={images.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GallerySection;