export const TESTIMONIALS = [
  { 
    text: "Me uno a las felicitaciones por el magnífico trabajo realizado por ustedes y todo su equipo en este muy valioso documental"
  },
  { 
    text: "En lo personal como hijo de sobreviviente me aportó mucho oír los testimonios de otros hijos de sobrevivientes, saber que varios sentimientos y vivencias a lo largo de nuestra vida las han tenido otros de manera similar. Fue incluso terapéutico para mi."
  },
  { 
    text: "A nosotros hijos de sobrevivientes el platicar como fue nuestra vida con nuestro padre nos ayuda a entenderlo, apreciarlo y hasta liberar sentimientos y traumas."
  },
  { 
    text: "La forma, el guion de las entrevistas la edición la música y todo el documental es magnífico."
  },
  { 
    text: "La edición me pareció extraordinaria y todas las secuencias muy bien logradas."
  },
  { 
    text: "Cuánto dolor lleva aún esta segunda generación."
  },
  { 
    text: "Es una excelente investigación que hicieron, y el resultado es sobresaliente. Las preguntas y cómo se eligió armar las entrevistas tenían mucho sentido."
  },
  { 
    text: "Es una obra de arte. Tejen como filigrana el diálogo con hijas e hijos de sobrevivientes, recogiendo las similitudes entre las historias de desconocidos, unidos por el destino de ser descendientes del Holocausto."
  },
  { 
    text: "Muy impresionante. ¿Cómo editaron eso?, qué bárbaros."
  },
  { 
    text: "Movió dentro de mí unas fibras que me nunca antes se habían movido con el tema del Holocausto."
  },
  { 
    text: "Los testimonios nos mueven todo, es un trabajo artístico que además tiene un gran valor educativo"
  },
  { 
    text: "Un legado importante para las generaciones futuras"
  },
  { 
    text: "El hecho de haber nacido en la casa de sobrevivientes del Holocausto nos motiva de una manera más intensa a luchar contra del olvido."
  },
  { 
    text: "El resultado es exquisito... es una película para analizar a fondo y reflexionar."
  },
  { 
    text: "Me encantó el documental! Me llegó al alma."
  },
  { 
    text: "Excelente el enfoque del documental. Jamás había escuchado sobre la vida de los"
  },
  { 
    text: "hijos de sobrevivientes con tanto detalle."
  },
  { 
    text: "Esta muy fuerte y sumamente conmovedor el documental..."
  },
  { 
    text: "El documental es sublime. Estupendo trabajo!"
  },
  { 
    text: "Es perfecto! Y la integración de los bailarines es una obra de arte, es un elemento casi onírico. Quedamos conmovidos y estremecidos!."
  },
  { 
    text: "Pocos son los que logran aportar con sus conocimientos y con su arte una visión diferente y nueva de la Shoa (Holocausto) y ustedes lo están haciendo."
  }
]