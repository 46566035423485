import React,{ useEffect, useState } from 'react'
import "magnific-popup";
import $ from "jquery";
import 'animate.css';
import { Link } from 'react-router-dom';
import Sound from 'react-sound';
import musicBg from '../../assets/media/intro-music.mp3';

const BannerLogin = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(()=> {
    $('.popup-video').magnificPopup({
			type: 'iframe',
      callbacks: {
        close: function() {
          setIsPlaying(true);
          return true;
        }
      }
		});

    // document.getElementById('btn-sound').click()
	
  }, []);


  return (
    <section className="banner-area banner-bg" style={{ backgroundImage: 'url("../img/site/header.jpeg")', minHeight: '100vh' }}>
      <div className="container custom-container">
        <div className="row">
          <div className="col-xl-6 col-lg-8">

            <div className="banner-content ">
              <div className="animate__animated animate__fadeInUp mb-4" data-wow-delay=".2s" data-wow-duration="1.8s">
                <img src="img/site/logotipo-bco_rojo-01.svg" alt="" />
              </div>
              <h2 className="title text-light animate__animated animate__fadeInUp" data-wow-delay=".4s" data-wow-duration="1.8s">Escuchando a los hijos de sobrevivientes del Holocausto en México.</h2>
              <h6 className="sub-title animate__animated animate__fadeInUp" data-wow-delay=".6s" data-wow-duration="1.8s">Plataforma realizada gracias al apoyo de la familia Feldman Nisenbaum.</h6>

              <a href="https://www.youtube.com/watch?v=rd3A3fKyCEU" 
                className="banner-btn btn popup-video wow fadeInUp" 
                data-wow-delay=".8s" 
                data-wow-duration="1.8s"
                onClick={() => setIsPlaying(false)}
              >
                <i className="fas fa-play" /> Ver trailer
              </a>
              <Link to="/login" className="banner-btn btn ml-4"><i className="fas fa-user" /> Ingresar</Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{
        position: 'absolute',
        bottom: 10,
        right: 10
      }}>
        <button 
          id="btn-sound" 
          className='btn btn-sound' 
          onClick={() => setIsPlaying(!isPlaying)}
        >
          { !isPlaying ? <i className="fas fa-play"></i> : <i className="fas fa-pause"></i> }
        </button>
        <Sound 
          url={musicBg}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
          volume={30}
          autoLoad={true}
          loop={true}
        />
      </div>
    </section>
  )
}

export default BannerLogin