import React, { useEffect, useState } from 'react';
import CardMovie from '../cardmovie/CardMovie';
import CarouselArea from '../carousel-area/CarouselArea';
import CardPerson from '../interviewed/CardPerson';

function MoviesSliderSection({ isBgDark, subtitle, current, interviews }) {
  const [carouselItems, setCarouselItems] = useState([])

  useEffect(() => {
    if (current) {
      let items = interviews.filter(item => item._id != current);
      setCarouselItems(getRandomItems(items, 10));
    } else {
      setCarouselItems(getRandomItems(interviews, 10));
    }
  }, [current, interviews])

  const getRandomItems = (arr, num) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  
  
  const buildCards = (item) => {
    return <CardPerson person={item} />
  }

  return (
    <section className={`interviewed-section section ${(isBgDark ? 'dark' : '')}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              { subtitle && <span className="sub-title">{ subtitle }</span>}
              <h2 className="title">Entrevistas más vistas</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center carousel-section">
          <div className="col-12">
            <CarouselArea 
              items={carouselItems}
              renderItems={buildCards}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MoviesSliderSection;