import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";
import $ from "jquery";
import { useCookies } from 'react-cookie';
import { LocalStorageService } from '../services/local-storage.service';
import { STORAGE } from '../constants';
import ReactGA from 'react-ga';

const Header = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const sess_token = LocalStorageService.get(STORAGE.TOKEN)
    const sess_user = LocalStorageService.get(STORAGE.SESSION)
    
    if (!sess_token || !sess_user) {
      doLogout()
    }

    ReactGA.pageview(window.location.pathname + window.location.search);

    /*=============================================
      =    		Mobile Menu			      =
    =============================================*/
    //SubMenu Dropdown Toggle
    if ($('.menu-area li.menu-item-has-children ul').length) {
      $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
    }
    //Mobile Nav Hide Show
    if ($('.mobile-menu').length) {

      var mobileMenuContent = $('.menu-area .main-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
        $(this).toggleClass('open');
        $(this).prev('ul').slideToggle(500);
      });
      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      //Menu Toggle Btn
      $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }

    /*=============================================
      =     Menu sticky & Scroll to top      =
    =============================================*/
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');

      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });


    /*=============================================
      =    		 Scroll Up  	         =
    =============================================*/
    if ($('.scroll-to-target').length) {
      $(".scroll-to-target").on('click', function () {
        var target = $(this).attr('data-target');
        // animate
        $('html, body').animate({
          scrollTop: $(target).offset().top
        }, 1000);

      });
    }

  }, [])

  const doLogout = () => {
    LocalStorageService.clear()
    history.push('/')
  }

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if (searchTerm !== '') {
      history.push('/search?term='+searchTerm)
    }
  }

  return (
    <header>
      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo">
                    <Link to="/home">
                      <img src="/img/site/logotipo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <NavLink exact activeClassName="active" to="/home">Inicio</NavLink>
                      </li>
                      <li className="">
                        <NavLink exact activeClassName="active" to="/murmullos">Murmullos del silencio</NavLink>
                      </li>
                      <li className="">
                        <NavLink exact activeClassName="active" to="/interviews">Entrevistas</NavLink>
                      </li>
                      <li className="">
                        <NavLink exact activeClassName="active" to="/project">Proyecto</NavLink>
                      </li>
                      <li className="">
                        <NavLink exact activeClassName="active" to="/contact">Contacto</NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="d-none d-xl-block">
                        <div className="footer-search footer-search-light">
                          <form onSubmit={onSubmitSearch}>
                            <input 
                              type="text" 
                              name="term" 
                              placeholder="Buscar entrevista" 
                              value={searchTerm}
                              onChange={onChangeSearch}
                            />
                            <button><i className="fas fa-search" /></button>
                          </form>
                        </div>
                      </li>
                      {/* <li className="header-search">
                      <a href="" data-toggle="modal" data-target="#search-modal">
                        <i className="fas fa-search" />
                      </a>
                    </li> */}
                      {/* <li className="header-lang">
                      <form action="/#">
                        <div className="icon"><i className="flaticon-globe" /></div>
                        <select id="lang-dropdown">
                          <option value>En</option>
                          <option value>Au</option>
                          <option value>AR</option>
                          <option value>TU</option>
                        </select>
                      </form>
                    </li> */}
                      <li className="header-btn"><button type="button" className="btn" onClick={doLogout}>Cerrar sesión</button></li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <div className="close-btn"><i className="fas fa-times" /></div>
                <nav className="menu-box">
                  <div className="nav-logo"><Link to="/home"><img src="/img/site/logotipo-bco.png" alt="" /></Link>
                  </div>
                  <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li><a href="https://www.facebook.com/MomentumFilmsMX/" target='_blank'><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="https://www.instagram.com/momentum.filmsmx/?igshid=MzNlNGNkZWQ4Mg==" target='_blank'><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
              {/* End Mobile Menu */}
              {/* Modal Search */}
              <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form>
                      <input type="text" placeholder="Buscar entrevista..." />
                      <button><i className="fas fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
              {/* Modal Search-end */}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
