import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewsletterArea from '../components/homeone/NewsletterArea'
import { Breadcrumb } from '../components/movie/Breadcrumb'
import MovieArea from '../components/movie/MovieArea'

const Movie = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <div>
     <Header/>
    <main>
        <Breadcrumb title="Entrevistas" />
        <MovieArea/>
        <NewsletterArea/>
    </main>
     <Footer/>
    </div>
  )
}

export default Movie