import React,{ useEffect } from 'react'
import { Link } from 'react-router-dom';

const EpipsodeArea = ({ videos }) => {
  
  return (
    <section className="episode-area episode-bg">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="movie-episode-wrap">
            <div className="episode-top-wrap">
              <div className="section-title">
                <h2 className="title text-primary">Entrevistas relacionadas</h2>
              </div>
            </div>
            <div className="episode-watch-wrap">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  {/* <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample"> */}
                  <div>
                    <div className="card-body">
                      <ul>
                        {
                          videos && videos.map((item, index) => {
                            return (
                              <li key={index}>
                                <Link to={`/movie-details/${item._id}`}><i className="fas fa-play" /> {item.name} </Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="episode-img">
            <img src="/img/site/img-entrevistas.jpeg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default EpipsodeArea