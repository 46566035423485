import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const AvisoPrivacidad = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <div>
      <Header />
      <div className="main">
        <section className="mb-4" style={{ padding: '100px 0' }}>
          <div className="container">
            <div className="section-title mb-5">
              <h2 className="title text-primary">Aviso de privacidad</h2>
            </div>
            <div className="row">
              <div className="col-12">
              <h5>Aviso de privacidad de datos personales</h5>
  <p>Aarón Cohen Hop es una persona física con actividades empresariales con domicilio en las calles de Extremadura 42 primer piso-47, Col. Insurgentes Mixcoac, Benito Juárez. C.P. 03920 es responsable de recabar sus datos personales, del uso que se le de a los mismos y de su protección. </p>
<br/>
  <h5>Datos personales que recabamos.</h5>
  <p>Aarón Cohen Hop, podrá recabar de usted datos personales de identificación, contacto, laborales, sobre características físicas, académicos, patrimoniales o financieros y biométricos, tales como: nombre completo, nacionalidad, domicilio, experiencia laboral, información académica o curricular, fecha de nacimiento, estado civil, edad, sexo, dirección de correo electrónico, números telefónicos, clave interbancaria, datos fiscales, CURP, número de seguridad social, número de IFE e información socioeconómica.</p>
<p></p>
  <p>Sus datos personales podrán ser recabados a través de los formatos de solicitud que utilice Aarón Cohen Hop de manera personal en nuestras oficinas o eventos, o a través de medios electrónicos.</p>
<p></p>
  <p>Además de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protección, tales como (i)Datos ideológicos, (ii)opiniones políticas, (iii)afiliación sindical, (iv)salud, (vi)de origen étnico o racial.</p>
  <br/>
  <h5>Uso de sus datos personales.</h5>
  <p>Sus datos personales serán utilizados para las siguientes finalidades: (i) la elaboración de encuestas; (ii) estudios de mercado; (iii) para fines estadísticos y académicos; (iv) dar cumplimiento a obligaciones contraídas con nuestros clientes (v) evaluar la calidad del servicio y, (vi) realizar estudios internos sobre hábitos de consumo.</p>
<p></p>
  <p>Sus datos personales serán utilizados para las siguientes finalidades: (i) la elaboración de encuestas; (ii) estudios de mercado; (iii) para fines estadísticos y académicos; (iv) dar cumplimiento a obligaciones contraídas con nuestros clientes (v) evaluar la calidad del servicio y, (vi) realizar estudios internos sobre hábitos de consumo.</p>
<p></p>
  <p>En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, desde este momento usted nos puede comunicar lo anterior, a través del procedimiento establecido en el apartado de derechos ARCO, del presente aviso.</p>
<p></p>
  <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
  <br/>
  <h5>Acceso, Rectificación, Cancelación y Oposición de los datos personales</h5>
  <p>Usted tiene el derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, a rectificarlos en caso de ser inexactos o incompletos. Asimismo, usted tiene el derecho de cancelar sus datos personales cuando considere que no se requieren para alguna de las finalidades señaladas en el presente aviso de privacidad, cuando estén siendo utilizadas para finalidades no consentidas o, en el caso, que haya finalizado la relación contractual o de servicio. Por último, usted también puede oponerse al tratamiento de sus datos personales para fines específicos.</p>
<p></p>
  <p>Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva a través de nuestra área de datos personales:</p>
<p></p>
  <p>Su solicitud deberá contener la siguiente información: (i) nombre completo, (ii) derecho que desee ejercer, (iii) datos que requiera acceder, ratificar, cancelar u oponerse a su tratamiento, (iv) en caso de que solicite la rectificación o actualización de sus datos, deberá proporcionar el dato que sustituirá al anterior; (v) en el supuesto de oposición al tratamiento de sus datos, deberá indicar el dato personal y el uso al cual se opone; (vi) en caso de cancelación o acceso, deberá indicar el dato a cancelar o acceder, según sea el caso.</p>
<p></p>
  <p>Asimismo, deberá acreditar su identidad acompañando copia simple de la identificación oficial del titular; o de su representante y documento con que acredite éste carácter.</p>
<p></p>
  <p>El plazo para atender su solicitud será de veinte días hábiles. La resolución se dará a conocer mediante el medio de comunicación a través del cual presentó su solicitud, excepto en los casos en que dicha solicitud se haya presentado por escrito en el domicilio del área de datos personales, ya que en tal supuesto, la resolución se dará a conocer por escrito en el domicilio del área de datos personales.</p>
<p></p>
  <p>Para mayor información, favor de comunicarse al área de datos personales mencionada anteriormente.</p>
  <br/>
  <h5>Revocación de consentimiento.</h5>
  <p>En todo momento, usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello, es necesario que presente su petición en el área de datos personales, de conformidad con el procedimiento establecido en el punto anterior.</p>
  <br/>
  <h5>Limitación al uso o divulgación de su información personal.</h5>
  <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:</p>
<p></p>
  <p>Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.</p>
  <p>Su registro en el listado de exclusión “Limitación de Titulares”, a fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios o de prospección comercial por nuestra parte. Para mayor información ponerse en contacto con el área de datos personales.</p>
  <br/>
  <h5>Modificaciones al aviso de privacidad.</h5>
  <p>Nos reservamos el derecho a efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad. Estas modificaciones estarán disponibles al público a través de los siguientes medios: (i) anuncios visibles en nuestros establecimientos o centros de atención a clientes; (ii) trípticos o folletos disponibles en nuestros establecimientos o centros de atención a clientes y, (iii) en nuestra página de Internet.</p>
  <br/>
  <h5>Cookies y web beacons.</h5>
  <p>Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor, nombre y contraseña. Por su parte, las web beacons son imágenes insertadas en una página de Internet o correo electrónico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros.</p>
  <br/>
  <h5>Quejas y denuncias.</h5>
  <p>Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados, nuestras actuaciones, respuestas o presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el IFAI, para mayor información visite www.ifai.org.mx.</p>

              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default AvisoPrivacidad