import React from 'react'
import Contacts from '../components/contacts/Contacts'
import Map from '../components/contacts/Map'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewsletterArea from '../components/homeone/NewsletterArea'
import { Breadcrumb } from '../components/movie/Breadcrumb'

const ContactPage = () => {
  return (
   <>
   <Header/>
   <main>
       <Breadcrumb title="Contacto" />
       <Contacts/>
       <NewsletterArea/>
   </main>
   <Footer/>
   </>
  )
}

export default ContactPage