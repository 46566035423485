import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const InterviewService = {
  getAll: (data) => {
    return HTTP.get(`${END_POINT}interviews`, data);
  },
  getDetail: (id) => {
    return HTTP.get(`${END_POINT}interviews/detail/${id}`);
  },
  getByCategory: (category) => {
    return HTTP.get(`${END_POINT}interviews/category/${category}`);
  },
  getRandom: (quantity, category = null) => {
    let extra = `?category=${category}`
    return HTTP.get(`${END_POINT}interviews/random/${quantity}${extra}`);
  },
  getPopular: (quantity) => {
    return HTTP.get(`${END_POINT}interviews/popular/${quantity}`)
  },
  getVideo: (videoId) => {
    return HTTP.get(`${END_POINT}interviews/video/${videoId}`);
  },
}