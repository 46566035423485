import React, { useEffect, useState } from 'react'
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import { InterviewService } from '../../services/interviewService';

const MovieDetail = ({ data }) => {
  const [interview, setInterview] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  useEffect(() => {
    setInterview(data)
  }, [data])

  const openPlayer = async(videoID) => {
    try {
      const response = await InterviewService.getVideo(videoID)
      setCurrentVideo(response)
      setIsPlaying(true);
    } catch (error) {
      console.log(error);
    }
  }

  const closePlayer = () => {
    setIsPlaying(close);
    setCurrentVideo(null);
  }

  const _renderResume = () => {
    // console.log('resume', interview)
    if (interview?.category && interview?.category.key.toLowerCase() === 'especialistas') { 
      return (<div className="text-dark" dangerouslySetInnerHTML={{ __html: interview.resume }} />)
    } else {
      return (<p className="text-dark">Mayo de 1945. Termina la Segunda Guerra Mundial y se liberan los campos de concentración. Cientos de miles de sobrevivientes del Holocausto son liberados. Al parecer es un momento feliz pero para ellos son momentos  de confrontación y trauma con su nueva realidad: de su deteriorada situación física, de la pérdida de sus familias, de sus hogares, e incluso de de sus países a los que ya no pueden regresar.</p>)
    }
  }

  const _renderDetails = () => {
    return (
      <section className="movie-details-area" style={{ backgroundImage: 'url("../img/site/header-interna.jpeg")' }}>
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-lg-4">
              <div className="movie-details-img">
                <img src={interview && interview.url_picture ? interview.url_picture : '/img/site/cover-default.png'} alt="" style={{ maxWidth: '100%' }} />
              </div>
              { interview && interview.category?.title.toLowerCase() !== 'especialistas' && (
                <div className="person-info pt-20 pb-5 pl-2 pr-2 text-dark">
                  <span><i className="fa fa-caret-right mr-2"></i>{`${interview.date_place_birth}`}</span><br/>
                  { interview.nationality && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Nacionalidad: </strong>{`${interview.nationality}`}</span><br/></>) }
                  { interview.religion && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Religion: </strong>{`${interview.religion}`}</span><br/></>) }
                  { interview.degree && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Estudios: </strong>{`${interview.degree}`}</span><br/></>) }
                  { interview.father && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Padre: </strong>{`${interview.father}`}</span><br/></>) }
                  { interview.father_birth && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Lugar de Nacimiento: </strong>{`${interview.father_birth}`}</span><br/></>) }
                  { interview.mother && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Madre: </strong>{`${interview.mother}`}</span><br/></>) }
                  { interview.mother_birth && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Lugar de nacimiento: </strong>{`${interview.mother_birth}`}</span><br/></>) }
                  { interview.siblings && (<><span><i className="fa fa-caret-right mr-2"></i><strong>Hermanos: </strong>{`${interview.siblings}`}</span><br/></>) }
                </div>
              )}
            </div>
            <div className="col-xl-6 col-lg-8">
              <div className="movie-details-content">
                <h5>{ interview && interview.category ? interview.category.title : '' }</h5>
                <h2>{ interview ? interview.name : '' }</h2>
                <div className="text-dark">
                  { _renderResume() }
                </div>
                {
                  interview && interview.videos.map(item => {
                    return (
                      <div className="movie-details-prime" key={`v-${item._id}`}>
                        <ul>
                          <li className="streaming">
                            <h6 className="text-light">{ item.title }</h6>
                          </li>
                          <li className="watch">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => openPlayer(item._id)}
                            >
                              <i className="fas fa-play" /> Ver ahora
                            </button>
                          </li>
                        </ul>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  const _renderPlayer = () => {
    return (
      <div className="section dark pt-2 pb-2">
        <div className="video-topbar mb-1">
          <div className="container">
            <div className="d-flex align-items-center">
              <button 
                className="btn-action" 
                onClick={closePlayer}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
              <h5>{`${interview.name}`}</h5>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="video-container" onContextMenu={e => e.preventDefault()}>
                <Player 
                  src={currentVideo.signedUrl}
                  autoPlay
                  preload="metadata"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    isPlaying ? _renderPlayer() : _renderDetails()
  )
}

export default MovieDetail