import React from 'react';
import { Link } from 'react-router-dom';

const CardPerson = ({ person }) => {
  return (
    <div className="card-person">
      <div className="person-photo">
        <Link to={`/movie-details/${person._id}`}>
          <img src={person.picture ? person.picture : 'img/site/cover-default.png'} alt="" />
        </Link>
      </div>
      <div className="person-content text-center">
        <h5 className="title">
          <Link to={`/movie-details/${person._id}`}>
            {person ? person.name : 'Nombre entrevistado'}
          </Link>
        </h5>
      </div>
    </div>
  )
};

export default CardPerson;