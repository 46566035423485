import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewsletterArea from '../components/homeone/NewsletterArea'
import { BreadcrumbSearch } from '../components/search/BreadcrumbSearch'
import SearchResultsArea from '../components/search/SearchResultsArea'
import {interviews} from '../data/interviews.const';
import useQuery from '../hooks/useQuery';
import { InterviewService } from '../services/interviewService'
import SearchLoader from '../components/search/SearchLoader'

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  let query = useQuery();

  useEffect(() => {
    setIsLoading(true)
    let term = query.get('term').trim();
    setSearchTerm(term);

    if (term) {
      loadInterviews(term)
    }

  }, [query])

  const loadInterviews = async (term) => {
    try {
      const response = await InterviewService.getAll()
      if (response) {
        let res = response.filter(item => {
          return item.name.toLowerCase().search(term.toLowerCase()) > -1
        })
  
        setIsLoading(false)
        setResults(res);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  return (
    <div>
     <Header/>
      <main>
        <BreadcrumbSearch title={searchTerm} />
        { isLoading ? <SearchLoader /> : <SearchResultsArea searchResults={results} /> }
        <NewsletterArea/>
      </main>
     <Footer/>
    </div>
  )
}

export default SearchPage