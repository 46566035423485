import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div 
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-circle-left"></i>
    </div>
  )
}

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div 
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-circle-right"></i>
    </div>
  )
}

function CarouselArea({ items, settings, renderItems }) {
  const [sliderSettings, setSliderSettings] = useState({})
  const defaultSettings = {
    slidesToShow: 4,
    speed: 500,
    arrows: true,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          autoplay: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 500,
          infinite: true,
        }
      },
      {
        breakpoint: 1500,
        settings: {
          autoplay: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 500,
          infinite: true,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 500,
          arrows: true,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          speed: 500,
          arrows: true,
          autoplay: false,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          slidesToShow: 2,
          speed: 500,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          arrows: false,
        }
      },
    ]
  };

  useEffect(() => {
    let st = {
      ...defaultSettings,
      ...settings
    }

    setSliderSettings(st)
  }, [settings])
  
  

  const buildRenderItems = () => {
    return items.map((item, index) => {
      return (
        <div className="pl-4 pr-4" key={index}>
          { renderItems(item) }
        </div>
      )
    })
  }

  return (
    <div className="carousel-area" style={{width: '100%'}}>
      <Slider {...sliderSettings}>
        { buildRenderItems() }
      </Slider>
    </div>
  )
}

export default CarouselArea;