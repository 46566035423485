import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Error404 } from '../components/errors/Error404'

function NotFound() {
  return (
    <>
    <Header />
      <Error404 title={'Página no '} />
      <main>
        <section className="movie-area movie-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

              </div>
            </div>
          </div>
        </section>
      </main>
    <Footer />
   </>
  )
}

export default NotFound