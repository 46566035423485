import React from 'react'
import "magnific-popup";
import $ from "jquery";

import 'animate.css';
import { Link } from 'react-router-dom';


const Banner = () => {
  
  return (
    <section className="banner-area banner-bg" style={{backgroundImage:'url("../img/site/header.jpeg")'}}>
    <div className="container custom-container">
      <div className="row">
        <div className="col-xl-6 col-lg-8">
       
         <div className="banner-content ">
            <div className="animate__animated animate__fadeInUp mb-4" data-wow-delay=".2s" data-wow-duration="1.8s">
              <img src="img/site/logotipo-bco_rojo-01.svg" alt="" />
            </div>
            <h2 className="title text-light animate__animated animate__fadeInUp" data-wow-delay=".4s" data-wow-duration="1.8s">Escuchando a los hijos de sobrevivientes del Holocausto en México.</h2>
            <h6 className="sub-title animate__animated animate__fadeInUp" data-wow-delay=".6s" data-wow-duration="1.8s">Plataforma realizada gracias al apoyo de la familia Feldman Nisenbaum.</h6>
            
            <Link to="/murmullos" className="banner-btn btn wow fadeInUp" data-wow-delay=".8s" data-wow-duration="1.8s">
              <i className="fas fa-play" /> Ver documental
            </Link>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  )
}

export default Banner