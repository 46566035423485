import React, { useEffect, useState } from 'react';
import { TESTIMONIALS } from '../../data/testimonials.conts';
import CarouselArea from '../carousel-area/CarouselArea';

const TestimonialSection = () => {
  const [testimonialList, setTestimonialList] = useState([])

  useEffect(() => {
    let itemsRandom = [...TESTIMONIALS].sort(() => (Math.random() > 0.5 ? 1 : -1)).slice(0, 5)

    setTestimonialList(itemsRandom)
  }, [])
  

  const buildCard = (item) => {
    return (
      <figure className="card-testimonial">
        <figcaption>
          <div className="testimonial-icon">
            <i className="fas fa-quote-left"></i>
          </div>
          <blockquote>
            <p>{item.text}</p>
          </blockquote>
          {/* <h3>Wisteria Ravenclaw</h3>
          <h4>Google Inc.</h4> */}
        </figcaption>
      </figure>
    )
  }

  return (
    <section className="interviewed-section section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <span className="sub-title">Documental</span>
              <h2 className="title">Opiniones</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center carousel-section">
          <div className="col-12">
            <CarouselArea 
              items={testimonialList}
              renderItems={buildCard}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialSection;