import React from 'react'
import { Link } from 'react-router-dom'

const NewsletterAreaDocumental = () => {
  return (
    <section className="newsletter-area newsletter-bg">
    <div className="container">
      <div className="newsletter-inner-wrap">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="newsletter-content">
              <h4>Visualiza las entrevistas de los hijos, sobrevivientes y especialistas del Holocauto.</h4>
              {/* <p>Enter your email to create or restart your membership.</p> */}
            </div>
          </div>
          <div className="col-lg-6 text-right">
            <Link className="btn btn-light" to="/interviews">
              <i className="fas fa-play"></i> Ver entrevistas
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default NewsletterAreaDocumental