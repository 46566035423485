import React from 'react'

function SearchLoader() {
  return (
    <section className="movie-area movie-bg">
      <div className="container">
        <div className="row ">
          <div className="col-12 text-center">
            <h4 className="text-center">Buscando</h4>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SearchLoader