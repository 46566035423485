import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MoviesSliderSection from '../components/homeone/MoviesSliderSection';
import NewsletterArea from '../components/homeone/NewsletterArea';
import EpipsodeArea from '../components/moviedetails/EpipsodeArea';
import MovieDetail from '../components/moviedetails/MovieDetail';
import { interviews } from '../data/interviews.const';
import { InterviewService } from '../services/interviewService';

const MovieDetails = () => {
  let { id } = useParams()
  const [interview, setInterview] = useState(null)
  const [associated, setAssociated] = useState([])
  const [randomInterviews, setRandomInterviews] = useState([])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (id) {
      loadInterview()
      loadRandomInterviews()
      // let search = interviews.find(item => item._id == id);
      // if (search) {
      //   // console.log(search)
      //   setInterview(search)
      //   window.scrollTo({ top: 0, behavior: 'smooth' });
      //   if (search.videos_asociados) {
      //     let array_asociados = search.videos_asociados.split(",").map(item => item.trim())
      //     let results = interviews.filter((item) => {
      //       return (item.video_historico_file && array_asociados.includes(item.video_historico_file))
      //         || (item.video_reflexivo_file && array_asociados.includes(item.video_reflexivo_file))
      //     })
      //     setAssociated(results)
      //   } else {
      //     setAssociated([]);
      //   }
      // } else {
      //   setInterview(null)
      // }
    } else {
      setInterview(null)
    }
  }, [id])
  
  const loadInterview = async () => {
    try {
      const response = await InterviewService.getDetail(id)
      setInterview(response)
      setAssociated(response.related_interviews)
    } catch (error) {
      console.log(error);
    }
  }

  const loadRandomInterviews = async () => {
    try {
      const response = await InterviewService.getRandom(10)
      setRandomInterviews(response)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
        <Header/>
        <main>
            <MovieDetail data={interview}/>
            <EpipsodeArea videos={associated}/>
            <MoviesSliderSection current={id} subtitle="Recomendaciones" interviews={randomInterviews} isBgDark />
            <NewsletterArea />
        </main>
        <Footer/>
    </div>
  )
}

export default MovieDetails;